import React from 'react';
import { Link } from 'react-router-dom';
import { Bot, Github, Twitter, Linkedin, Mail, ArrowUpRight } from 'lucide-react';
import useToolStore from '../store/toolStore';

export default function Footer() {
  const { categories = [] } = useToolStore();

  return (
    <footer 
      className="bg-card border-t border-border mt-24 w-full"
      style={{ 
        minHeight: '400px',
        contain: 'layout style paint',
        containIntrinsicSize: '0 400px',
        contentVisibility: 'auto'
      }}
    >
      <div 
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12"
        style={{
          minHeight: '376px', // 400px - 24px margin top
          contain: 'layout style'
        }}
      >
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Brand */}
          <div className="space-y-4">
            <div className="flex items-center gap-2 h-8">
              <Bot className="w-8 h-8 text-primary" aria-hidden="true" />
              <span className="text-xl font-bold text-foreground">AIToolzy</span>
            </div>
            <p className="text-muted-foreground max-w-[250px]">
              Discover and compare the best AI tools to enhance your workflow and boost productivity.
            </p>
            <div className="flex space-x-4">
              <a 
                href="https://github.com/aitoolzy" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-muted-foreground hover:text-foreground transition-colors"
                aria-label="Visit AIToolzy on GitHub"
              >
                <Github className="w-5 h-5" aria-hidden="true" />
                <span className="sr-only">Visit AIToolzy on GitHub</span>
              </a>
              <a 
                href="https://twitter.com/aitoolzy" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-muted-foreground hover:text-foreground transition-colors"
                aria-label="Follow AIToolzy on Twitter"
              >
                <Twitter className="w-5 h-5" aria-hidden="true" />
                <span className="sr-only">Follow AIToolzy on Twitter</span>
              </a>
              <a 
                href="https://linkedin.com/company/aitoolzy" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-muted-foreground hover:text-foreground transition-colors"
                aria-label="Connect with AIToolzy on LinkedIn"
              >
                <Linkedin className="w-5 h-5" aria-hidden="true" />
                <span className="sr-only">Connect with AIToolzy on LinkedIn</span>
              </a>
              <a 
                href="mailto:contact@aitoolzy.com" 
                className="text-muted-foreground hover:text-foreground transition-colors"
                aria-label="Email AIToolzy at contact@aitoolzy.com"
              >
                <Mail className="w-5 h-5" aria-hidden="true" />
                <span className="sr-only">Email AIToolzy at contact@aitoolzy.com</span>
              </a>
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 id="quick-links-title" className="text-foreground font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2" role="list" aria-labelledby="quick-links-title">
              <li>
                <Link 
                  to="/" 
                  className="text-muted-foreground hover:text-foreground transition-colors"
                  aria-label="Go to home page"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link 
                  to="/about" 
                  className="text-muted-foreground hover:text-foreground transition-colors"
                  aria-label="Learn more about AIToolzy"
                >
                  About
                </Link>
              </li>
              <li>
                <Link 
                  to="/blog" 
                  className="text-muted-foreground hover:text-foreground transition-colors"
                  aria-label="Read our blog posts"
                >
                  Blog
                </Link>
              </li>
              <li>
                <Link 
                  to="/contact" 
                  className="text-muted-foreground hover:text-foreground transition-colors"
                  aria-label="Contact us"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          {/* Categories */}
          <div>
            <h3 id="categories-title" className="text-foreground font-semibold mb-4">Categories</h3>
            <ul className="space-y-2" role="list" aria-labelledby="categories-title">
              {categories.slice(0, 6).map((category) => (
                <li key={category}>
                  <Link
                    to={`/categories?category=${encodeURIComponent(category)}`}
                    className="text-muted-foreground hover:text-foreground transition-colors flex items-center gap-1 group"
                    aria-label={`Browse ${category} AI tools`}
                  >
                    {category}
                    <ArrowUpRight 
                      className="w-4 h-4 opacity-0 -translate-x-2 group-hover:opacity-100 group-hover:translate-x-0 transition-all" 
                      aria-hidden="true"
                    />
                  </Link>
                </li>
              ))}
              <li>
                <Link 
                  to="/categories"
                  className="text-primary hover:text-primary/80 transition-colors flex items-center gap-1 group"
                  aria-label="View all AI tool categories"
                >
                  View All Categories
                  <ArrowUpRight 
                    className="w-4 h-4 opacity-0 -translate-x-2 group-hover:opacity-100 group-hover:translate-x-0 transition-all"
                    aria-hidden="true" 
                  />
                </Link>
              </li>
            </ul>
          </div>

          {/* Legal */}
          <div>
            <h3 id="legal-title" className="text-foreground font-semibold mb-4">Legal</h3>
            <ul className="space-y-2" role="list" aria-labelledby="legal-title">
              <li>
                <Link to="/privacy" className="text-muted-foreground hover:text-foreground transition-colors" aria-label="View our privacy policy">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/terms" className="text-muted-foreground hover:text-foreground transition-colors" aria-label="View our terms of service">
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link to="/cookies" className="text-muted-foreground hover:text-foreground transition-colors" aria-label="View our cookie policy">
                  Cookie Policy
                </Link>
              </li>
              <li>
                <Link to="/affiliate-disclosure" className="text-muted-foreground hover:text-foreground transition-colors" aria-label="View our affiliate disclosure">
                  Affiliate Disclosure
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-border py-8 text-center md:flex md:justify-between">
          <p className="text-muted-foreground">
            {new Date().getFullYear()} AIToolzy. All rights reserved.
          </p>
          <div className="mt-4 md:mt-0 space-x-6">
            <Link to="/privacy" className="text-muted-foreground hover:text-foreground transition-colors" aria-label="View our privacy policy">
              Privacy
            </Link>
            <Link to="/terms" className="text-muted-foreground hover:text-foreground transition-colors" aria-label="View our terms of service">
              Terms
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}