import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';

// Ensure we only create root once
let root: ReturnType<typeof createRoot> | null = null;

const render = () => {
  const container = document.getElementById('root');
  if (!container) return;

  if (!root) {
    root = createRoot(container);
  }

  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
};

// Initialize theme
const theme = localStorage.getItem('theme-storage')
  ? JSON.parse(localStorage.getItem('theme-storage')!).state.theme
  : window.matchMedia('(prefers-color-scheme: dark)').matches
  ? 'dark'
  : 'light';

document.documentElement.classList.toggle('dark', theme === 'dark');

// Initial render
render();

// Handle HMR
if (import.meta.hot) {
  import.meta.hot.accept('./App', () => {
    render();
  });
}