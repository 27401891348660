// Critical CSS variables
export const criticalCSS = `
:root {
  --background: #FFFFFF;
  --foreground: #1E293B;
  --card: #FFFFFF;
  --card-foreground: #1E293B;
  --primary: #2563EB;
  --primary-foreground: #FFFFFF;
  --border: #E2E8F0;
}

.dark {
  --background: #0C1015;
  --foreground: #FFFFFF;
  --card: #151B26;
  --card-foreground: #FFFFFF;
  --primary: #3B82F6;
  --primary-foreground: #FFFFFF;
  --border: #1E293B;
}

/* Critical Tailwind utilities */
.min-h-screen { min-height: 100vh; }
.bg-background { background-color: var(--background); }
.text-foreground { color: var(--foreground); }
.flex { display: flex; }
.flex-col { flex-direction: column; }
.items-center { align-items: center; }
.justify-center { justify-content: center; }
.w-full { width: 100%; }
.h-full { height: 100%; }
.p-4 { padding: 1rem; }
.m-4 { margin: 1rem; }
.font-sans { font-family: 'Space Grotesk', ui-sans-serif, system-ui, sans-serif; }

/* Loading state styles */
.loading-spinner {
  border: 3px solid var(--border);
  border-radius: 50%;
  border-top: 3px solid var(--primary);
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`;

// Function to inject critical CSS
export const injectCriticalCSS = () => {
  if (typeof document === 'undefined') return;

  const style = document.getElementById('critical-css');
  if (style) {
    style.textContent = criticalCSS;
  }
};

// Function to load non-critical CSS
export const loadNonCriticalCSS = () => {
  if (typeof document === 'undefined') return;

  // Let Vite handle the CSS loading through its plugin system
  // The CSS will be automatically injected by Vite during build
  return;
};
