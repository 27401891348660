import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Bot, Plus, LogOut, Moon, Sun, BookOpen } from 'lucide-react';
import useAuthStore from '../store/authStore';
import useThemeStore from '../store/themeStore';

export default function Header() {
  const navigate = useNavigate();
  const { isAuthenticated, isAdmin, user, logout } = useAuthStore();
  const { theme, toggleTheme } = useThemeStore();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <header className="sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="container flex h-14 items-center">
        <Link to="/" className="flex items-center gap-2 mr-6 shrink-0" aria-label="AIToolzy Home">
          <div className="flex h-8 w-8 items-center justify-center rounded-lg bg-primary shrink-0">
            <Bot className="h-5 w-5 text-primary-foreground" aria-hidden="true" />
          </div>
          <span className="hidden font-bold sm:inline-block">
            AIToolzy
          </span>
        </Link>

        <nav className="flex flex-1 items-center justify-between space-x-2 md:justify-end">
          <div className="hidden md:flex items-center gap-6 h-14">
            <Link 
              to="/tools" 
              className="text-sm font-medium transition-colors hover:text-primary h-14 flex items-center"
            >
              Tools
            </Link>
            <Link 
              to="/categories" 
              className="text-sm font-medium transition-colors hover:text-primary h-14 flex items-center"
            >
              Categories
            </Link>
            <Link 
              to="/blog" 
              className="text-sm font-medium transition-colors hover:text-primary h-14 flex items-center"
            >
              Blog
            </Link>
            <Link 
              to="/submit-tool" 
              className="text-sm font-medium transition-colors hover:text-primary h-14 flex items-center"
            >
              Submit Tool
            </Link>
            {isAdmin && (
              <Link 
                to="/admin" 
                className="text-sm font-medium transition-colors hover:text-primary h-14 flex items-center"
              >
                Admin
              </Link>
            )}
          </div>

          <div className="flex items-center gap-2 h-14">
            <button
              onClick={toggleTheme}
              className="btn btn-secondary h-9 w-9 px-0 shrink-0"
              aria-label={theme === 'dark' ? 'Switch to light mode' : 'Switch to dark mode'}
            >
              {theme === 'dark' ? (
                <>
                  <Sun className="h-4 w-4" aria-hidden="true" />
                  <span className="sr-only">Switch to light mode</span>
                </>
              ) : (
                <>
                  <Moon className="h-4 w-4" aria-hidden="true" />
                  <span className="sr-only">Switch to dark mode</span>
                </>
              )}
            </button>

            {isAuthenticated ? (
              <div className="flex items-center gap-2">
                {isAdmin && (
                  <Link to="/admin/add" className="btn btn-primary h-9 px-4 shrink-0">
                    <Plus className="mr-2 h-4 w-4" aria-hidden="true" />
                    Add Tool
                  </Link>
                )}
                <span className="text-sm truncate max-w-[100px]">{user?.name}</span>
                <button onClick={handleLogout} className="btn btn-secondary h-9 px-4 shrink-0">
                  <LogOut className="mr-2 h-4 w-4" aria-hidden="true" />
                  Logout
                </button>
              </div>
            ) : (
              <Link to="/login" className="btn btn-secondary h-9 px-4 shrink-0">
                Login
              </Link>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
}