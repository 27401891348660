import { create } from 'zustand';
import { AuthState, User } from '../types/auth';
import { supabase } from '../config/supabase';

// Admin credentials configuration
const ADMIN_CREDENTIALS = {
  id: '1',
  email: 'admin@admin.com',
  password: 'spiderman',
  name: 'Admin User',
  role: 'admin' as const,
};

// Demo user for testing
const DEMO_USER = {
  id: '2',
  email: 'user@example.com',
  password: 'user123',
  name: 'Regular User',
  role: 'user' as const,
};

const DEMO_USERS = [ADMIN_CREDENTIALS, DEMO_USER];

const useAuthStore = create<AuthState>((set) => ({
  user: null,
  token: null,
  isAuthenticated: false,
  isAdmin: false,

  login: async (email: string, password: string) => {
    const user = DEMO_USERS.find(u => u.email === email && u.password === password);
    
    if (!user) {
      throw new Error('Invalid credentials');
    }

    const token = btoa(JSON.stringify(user));
    sessionStorage.setItem('auth_token', token);
    
    set({
      user,
      token,
      isAuthenticated: true,
      isAdmin: user.role === 'admin',
    });
  },

  register: async (email: string, password: string, name: string) => {
    if (DEMO_USERS.some(u => u.email === email)) {
      throw new Error('Email already exists');
    }

    const newUser = {
      id: crypto.randomUUID(),
      email,
      name,
      role: 'user' as const,
    };

    const token = btoa(JSON.stringify(newUser));
    sessionStorage.setItem('auth_token', token);

    set({
      user: newUser,
      token,
      isAuthenticated: true,
      isAdmin: false,
    });
  },

  logout: () => {
    sessionStorage.removeItem('auth_token');
    set({
      user: null,
      token: null,
      isAuthenticated: false,
      isAdmin: false,
    });
  },
}));

// Initialize auth state from sessionStorage
const token = sessionStorage.getItem('auth_token');
if (token) {
  try {
    const user = JSON.parse(atob(token));
    useAuthStore.setState({
      user,
      token,
      isAuthenticated: true,
      isAdmin: user.role === 'admin',
    });
  } catch (error) {
    sessionStorage.removeItem('auth_token');
  }
}

export default useAuthStore;