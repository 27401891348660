import { create } from 'zustand';
import { Tool } from '../types/tool';
import { supabase } from '../config/supabase';

const generateSlug = (name: string): string => {
  return name
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\s-]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')
    .replace(/^-+|-+$/g, '');
};

interface ToolState {
  tools: Tool[];
  categories: string[];
  isLoading: boolean;
  error: string | null;
  fetchTools: () => Promise<void>;
  getToolBySlug: (slug: string) => Promise<Tool | null>;
  addTool: (tool: any) => Promise<any>;
  updateTool: (slug: string, tool: Partial<Tool>) => Promise<void>;
  deleteTool: (slug: string) => Promise<void>;
  addCategory: (category: string) => void;
  removeCategory: (category: string) => void;
  migrateToolSlugs: () => Promise<void>;
}

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const useToolStore = create<ToolState>((set, get) => ({
  tools: [],
  categories: [
    'Language Models',
    'Image Generation', 
    'Code Generation',
    'Data Analysis',
    'Audio & Speech',
    'Video Generation',
    'Marketing & SEO',
    'Research & Analysis',
    'Automation',
    'Business & Productivity',
    'Design & Creative',
    'Education & Learning',
    'Healthcare & Medical',
    'Legal & Compliance'
  ],
  isLoading: false,
  error: null,

  fetchTools: async () => {
    try {
      console.log('Fetching all tools...');
      const { data: tools, error } = await supabase
        .from('tools')
        .select('*')
        .order('name');

      if (error) {
        console.error('Error fetching tools:', error);
        throw error;
      }

      if (!tools) {
        console.error('No tools found in database');
        return;
      }

      console.log('Fetched tools from database:', tools);

      // Helper function to parse array fields
      const parseArrayField = (field: any): string[] => {
        if (Array.isArray(field)) return field;
        if (typeof field === 'string') {
          try {
            const parsed = JSON.parse(field);
            return Array.isArray(parsed) ? parsed : [];
          } catch {
            return field.split('\n')
              .map(line => line.trim())
              .filter(Boolean)
              .map(line => line.startsWith('-') ? line.substring(1).trim() : line);
          }
        }
        return [];
      };

      // Helper function to parse FAQ field
      const parseFaqs = (field: any): Array<{ question: string; answer: string }> => {
        if (Array.isArray(field)) return field;
        if (typeof field === 'string') {
          try {
            const parsed = JSON.parse(field);
            return Array.isArray(parsed) ? parsed : [];
          } catch {
            return [];
          }
        }
        return [];
      };

      // Format the tools
      const formattedTools = tools.map(tool => ({
        ...tool,
        categories: parseArrayField(tool.categories),
        tags: parseArrayField(tool.tags),
        features: parseArrayField(tool.features),
        use_cases: parseArrayField(tool.use_cases),
        pros: parseArrayField(tool.pros),
        cons: parseArrayField(tool.cons),
        tutorials: parseArrayField(tool.tutorials),
        video_resources: parseArrayField(tool.video_resources),
        online_courses: parseArrayField(tool.online_courses),
        awards: parseArrayField(tool.awards),
        testimonials: parseArrayField(tool.testimonials),
        integration_guides: parseArrayField(tool.integration_guides),
        faqs: parseFaqs(tool.faqs),
        pricing: typeof tool.pricing === 'string' ? JSON.parse(tool.pricing) : tool.pricing || {
          type: 'free',
          startingPrice: 0,
          tiers: [{
            name: 'Free',
            price: 0,
            billingPeriod: 'free',
            features: ['Basic features']
          }]
        },
        seo: {
          title: tool.seo?.title || tool.name || '',
          description: tool.seo?.description || tool.description?.substring(0, 160) || '',
          keywords: tool.seo?.keywords || tool.tags?.join(', ') || '',
          slug: tool.seo?.slug || tool.slug || '',
          canonicalUrl: tool.seo?.canonicalUrl || `https://aitoolzy.com/tool/${tool.slug}`
        },
        community: tool.community || '',
        system_requirements: tool.system_requirements || '',
        release_notes: tool.release_notes || '',
        security: tool.security || '',
        rating: tool.rating || 0,
        reviews: tool.reviews || []
      }));

      console.log('Formatted tools:', formattedTools);

      set({ tools: formattedTools });
    } catch (error) {
      console.error('Error in fetchTools:', error);
      throw error;
    }
  },

  getToolBySlug: async (slug: string) => {
    try {
      console.log('Getting tool by slug:', slug);
      
      // First check the store
      const state = useToolStore.getState();
      console.log('Current tools in store:', state.tools.length);
      
      const toolFromStore = state.tools.find(t => t.slug === slug);
      
      if (toolFromStore) {
        console.log('Found tool in store:', toolFromStore);
        return toolFromStore;
      }

      // If not in store, fetch from database
      console.log('Fetching tool from database...');
      const { data: tools, error } = await supabase
        .from('tools')
        .select('*')
        .eq('slug', slug)
        .single();

      if (error) {
        console.error('Error fetching tool:', error);
        throw error;
      }

      if (!tools) {
        console.error('No tool found with slug:', slug);
        throw new Error(`Tool not found with slug: ${slug}`);
      }

      const rawTool = tools;
      console.log('Found raw tool in database:', rawTool);

      // Helper function to parse array fields
      const parseArrayField = (field: any): string[] => {
        if (Array.isArray(field)) return field;
        if (typeof field === 'string') {
          try {
            const parsed = JSON.parse(field);
            return Array.isArray(parsed) ? parsed : [];
          } catch {
            return field.split('\n')
              .map(line => line.trim())
              .filter(Boolean)
              .map(line => line.startsWith('-') ? line.substring(1).trim() : line);
          }
        }
        return [];
      };

      // Helper function to parse FAQ field
      const parseFaqs = (field: any): Array<{ question: string; answer: string }> => {
        if (Array.isArray(field)) return field;
        if (typeof field === 'string') {
          try {
            const parsed = JSON.parse(field);
            return Array.isArray(parsed) ? parsed : [];
          } catch {
            return [];
          }
        }
        return [];
      };

      // Format the tool data
      const formattedTool = {
        ...rawTool,
        categories: parseArrayField(rawTool.categories),
        tags: parseArrayField(rawTool.tags),
        features: parseArrayField(rawTool.features),
        use_cases: parseArrayField(rawTool.use_cases),
        pros: parseArrayField(rawTool.pros),
        cons: parseArrayField(rawTool.cons),
        tutorials: parseArrayField(rawTool.tutorials),
        video_resources: parseArrayField(rawTool.video_resources),
        online_courses: parseArrayField(rawTool.online_courses),
        awards: parseArrayField(rawTool.awards),
        testimonials: parseArrayField(rawTool.testimonials),
        integration_guides: parseArrayField(rawTool.integration_guides),
        faqs: parseFaqs(rawTool.faqs),
        pricing: typeof rawTool.pricing === 'string' ? JSON.parse(rawTool.pricing) : rawTool.pricing || {
          type: 'free',
          startingPrice: 0,
          tiers: [{
            name: 'Free',
            price: 0,
            billingPeriod: 'free',
            features: ['Basic features']
          }]
        },
        seo: {
          title: rawTool.seo?.title || rawTool.name || '',
          description: rawTool.seo?.description || rawTool.description?.substring(0, 160) || '',
          keywords: rawTool.seo?.keywords || rawTool.tags?.join(', ') || '',
          slug: rawTool.seo?.slug || rawTool.slug || '',
          canonicalUrl: rawTool.seo?.canonicalUrl || `https://aitoolzy.com/tool/${rawTool.slug}`
        },
        community: rawTool.community || '',
        system_requirements: rawTool.system_requirements || '',
        release_notes: rawTool.release_notes || '',
        security: rawTool.security || '',
        rating: rawTool.rating || 0,
        reviews: rawTool.reviews || []
      };

      console.log('Formatted tool:', formattedTool);
      return formattedTool;
    } catch (error) {
      console.error('Error in getToolBySlug:', error);
      throw error;
    }
  },

  formatToolData: (data: any) => {
    const parseBulletList = (str: any) => {
      if (!str) return [];
      if (Array.isArray(str)) return str;
      if (typeof str !== 'string') return [];
      return str
        .split('\n')
        .map((line: string) => line.replace(/^-+\s*/, '').trim())
        .filter((item: string) => item !== '');
    };

    const formatFaqs = (faqs: any) => {
      if (typeof faqs === 'string') {
        try {
          const parsed = JSON.parse(faqs);
          return Array.isArray(parsed) ? parsed : [];
        } catch {
          return [];
        }
      }
      return Array.isArray(faqs) ? faqs : [];
    };

    const formatPricing = (pricing: any) => {
      if (!pricing) {
        return {
          type: 'free',
          startingPrice: 0,
          tiers: [{
            name: 'Free',
            price: 0,
            billingPeriod: 'free',
            features: ['Basic features']
          }]
        };
      }
      if (typeof pricing === 'string') {
        try {
          pricing = JSON.parse(pricing);
        } catch {
          return {
            type: 'free',
            startingPrice: 0,
            tiers: [{
              name: 'Free',
              price: 0,
              billingPeriod: 'free',
              features: ['Basic features']
            }]
          };
        }
      }
      return {
        type: pricing.type || 'free',
        startingPrice: pricing.startingPrice || 0,
        tiers: Array.isArray(pricing.tiers) ? pricing.tiers : [{
          name: pricing.type === 'free' ? 'Free' : 'Basic',
          price: pricing.type === 'free' ? 0 : (pricing.startingPrice || 9.99),
          billingPeriod: pricing.type === 'free' ? 'free' : 'monthly',
          features: Array.isArray(pricing.tiers?.[0]?.features) ? pricing.tiers[0].features : ['Basic features']
        }]
      };
    };

    const formattedPricing = formatPricing(data.pricing);
    const tags = typeof data.tags === 'string' ? 
      data.tags.split(',').map((t: string) => t.trim()).filter(Boolean) : 
      Array.isArray(data.tags) ? data.tags : [];

    const formattedData = {
      name: data.name || '',
      description: data.description || '',
      url: data.url || '',
      affiliate_url: data.affiliateUrl || data.affiliate_url || '',
      image_url: data.imageUrl || data.image_url || '',
      alt_text: data.alt_text || `${data.name} interface screenshot`,
      slug: data.slug || '',
      categories: Array.isArray(data.categories) ? data.categories : [],
      tags,
      features: parseBulletList(data.features),
      use_cases: parseBulletList(data.useCases || data.use_cases),
      pros: parseBulletList(data.pros),
      cons: parseBulletList(data.cons),
      tutorials: parseBulletList(data.tutorials),
      video_resources: parseBulletList(data.videoResources || data.video_resources),
      online_courses: parseBulletList(data.onlineCourses || data.online_courses),
      community: data.community || '',
      faqs: formatFaqs(data.faqs),
      system_requirements: data.systemRequirements || data.system_requirements || '',
      release_notes: data.releaseNotes || data.release_notes || '',
      awards: parseBulletList(data.awards),
      security: data.security || '',
      testimonials: parseBulletList(data.testimonials),
      integration_guides: parseBulletList(data.integrationGuides || data.integration_guides),
      pricing: formattedPricing,
      price_type: formattedPricing.type,
      rating: data.rating || 0,
      reviews: Array.isArray(data.reviews) ? data.reviews : [],
      created_at: data.created_at || new Date().toISOString(),
      updated_at: new Date().toISOString(),
      seo: {
        title: data.seo?.title || data.name || '',
        description: data.seo?.description || data.description?.substring(0, 160) || '',
        keywords: data.seo?.keywords || tags.join(', '),
        slug: data.seo?.slug || data.slug || '',
        canonicalUrl: data.seo?.canonicalUrl || `https://aitoolzy.com/tool/${data.slug}`
      }
    };

    console.log('Formatted tool data:', formattedData);
    return formattedData;
  },

  formatToolForStore: (data: any) => {
    const tool = {
      ...data,
      imageUrl: data.image_url,
      imageAltText: data.alt_text,
      affiliateUrl: data.affiliate_url,
      categories: Array.isArray(data.categories) ? data.categories : [data.categories],
      tags: Array.isArray(data.tags) ? data.tags : [],
      useCases: data.use_cases,
      videoResources: data.video_resources,
      onlineCourses: data.online_courses,
      systemRequirements: data.system_requirements,
      releaseNotes: data.release_notes,
      integrationGuides: data.integration_guides,
      features: Array.isArray(data.features) ? data.features : [],
      pros: Array.isArray(data.pros) ? data.pros : [],
      cons: Array.isArray(data.cons) ? data.cons : [],
      tutorials: Array.isArray(data.tutorials) ? data.tutorials : [],
      testimonials: Array.isArray(data.testimonials) ? data.testimonials : [],
      awards: Array.isArray(data.awards) ? data.awards : [],
      faqs: Array.isArray(data.faqs) ? data.faqs : []
    };
    return tool;
  },

  addTool: async (toolData) => {
    set({ isLoading: true, error: null });
    try {
      const baseSlug = generateSlug(toolData.name);
      
      const { data: existingSlugs } = await supabase
        .from('tools')
        .select('slug')
        .like('slug', `${baseSlug}%`);

      let slug = baseSlug;
      let counter = 1;
      while (existingSlugs?.some(tool => tool.slug === slug)) {
        slug = `${baseSlug}-${counter}`;
        counter++;
      }

      const formattedData = useToolStore.getState().formatToolData({ ...toolData, slug });

      const { data, error } = await supabase
        .from('tools')
        .insert([formattedData])
        .select()
        .single();

      if (error) throw error;

      const formattedTool = useToolStore.getState().formatToolForStore(data || formattedData);
      
      const { tools } = get();
      set({ 
        tools: [formattedTool, ...tools], 
        isLoading: false,
        error: null 
      });

      return formattedTool;
    } catch (error: any) {
      console.error('Error adding tool:', error);
      set({ error: error.message || 'Failed to add tool', isLoading: false });
      throw error;
    }
  },

  // Helper function to safely parse JSON
  safeJSONParse: (str: string, fallback: any = null) => {
    if (!str) return fallback;
    try {
      // Remove trailing commas
      const cleanStr = str.replace(/,(\s*[}\]])/g, '$1');
      return JSON.parse(cleanStr);
    } catch (e) {
      console.error('Error parsing JSON:', e);
      return fallback;
    }
  },

  updateTool: async (slug: string, toolData: any) => {
    try {
      console.log('Updating tool with slug:', slug);
      console.log('Tool data:', toolData);

      // Ensure arrays are properly formatted
      const formatArrayField = (field: any) => {
        if (Array.isArray(field)) {
          return field;
        }
        if (typeof field === 'string') {
          try {
            const parsed = JSON.parse(field);
            return Array.isArray(parsed) ? parsed : [];
          } catch {
            // Split by newlines and handle bullet points
            return field.split('\n')
              .map(line => line.trim())
              .filter(Boolean) // Remove empty lines
              .map(line => line.startsWith('-') ? line.substring(1).trim() : line); // Remove leading dash if present
          }
        }
        return [];
      };

      // Format complex objects
      const formatComplexField = (field: any) => {
        if (typeof field === 'string') {
          try {
            return JSON.parse(field);
          } catch {
            return field;
          }
        }
        return field;
      };

      // Prepare data for database
      const dbToolData = {
        ...toolData,  // Keep all original fields
        categories: formatArrayField(toolData.categories),
        tags: formatArrayField(toolData.tags),
        features: formatArrayField(toolData.features),
        use_cases: formatArrayField(toolData.use_cases),
        pros: formatArrayField(toolData.pros),
        cons: formatArrayField(toolData.cons),
        tutorials: formatArrayField(toolData.tutorials),
        video_resources: formatArrayField(toolData.video_resources),
        online_courses: formatArrayField(toolData.online_courses),
        awards: formatArrayField(toolData.awards),
        testimonials: formatArrayField(toolData.testimonials),
        integration_guides: formatArrayField(toolData.integration_guides),
        pricing: formatComplexField(toolData.pricing),
        faqs: formatComplexField(toolData.faqs),
        seo: {
          title: toolData.seo?.title || toolData.name || '',
          description: toolData.seo?.description || toolData.description?.substring(0, 160) || '',
          keywords: toolData.seo?.keywords || toolData.tags?.join(', ') || '',
          slug: toolData.seo?.slug || toolData.slug || '',
          canonicalUrl: toolData.seo?.canonicalUrl || `https://aitoolzy.com/tool/${toolData.slug}`
        },
        community: toolData.community || '',
        system_requirements: toolData.system_requirements || toolData.systemRequirements || '',
        release_notes: toolData.release_notes || toolData.releaseNotes || '',
        security: toolData.security || ''
      };

      console.log('Formatted tool data for update:', dbToolData);

      const { data, error } = await supabase
        .from('tools')
        .update(dbToolData)
        .eq('slug', slug)
        .select()
        .single();

      if (error) {
        console.error('Error updating tool in database:', error);
        throw error;
      }

      // Format the tool for the store
      const formattedTool = {
        ...data,
        categories: Array.isArray(data.categories) ? data.categories : [],
        tags: Array.isArray(data.tags) ? data.tags : [],
        features: Array.isArray(data.features) ? data.features : [],
        use_cases: Array.isArray(data.use_cases) ? data.use_cases : [],
        pros: Array.isArray(data.pros) ? data.pros : [],
        cons: Array.isArray(data.cons) ? data.cons : [],
        tutorials: Array.isArray(data.tutorials) ? data.tutorials : [],
        video_resources: Array.isArray(data.video_resources) ? data.video_resources : [],
        online_courses: Array.isArray(data.online_courses) ? data.online_courses : [],
        awards: Array.isArray(data.awards) ? data.awards : [],
        testimonials: Array.isArray(data.testimonials) ? data.testimonials : [],
        integration_guides: Array.isArray(data.integration_guides) ? data.integration_guides : [],
        pricing: typeof data.pricing === 'string' ? JSON.parse(data.pricing) : data.pricing || {
          type: 'free',
          startingPrice: 0,
          tiers: [{
            name: 'Free',
            price: 0,
            billingPeriod: 'free',
            features: ['Basic features']
          }]
        },
        faqs: typeof data.faqs === 'string' ? JSON.parse(data.faqs) : data.faqs || [],
        seo: {
          title: data.seo?.title || data.name || '',
          description: data.seo?.description || data.description?.substring(0, 160) || '',
          keywords: data.seo?.keywords || data.tags?.join(', ') || '',
          slug: data.seo?.slug || data.slug || '',
          canonicalUrl: data.seo?.canonicalUrl || `https://aitoolzy.com/tool/${data.slug}`
        }
      };

      console.log('Formatted tool for store:', formattedTool);

      // Update the tool in the store
      const { tools } = get();
      const updatedTools = tools.map(t => t.slug === slug ? formattedTool : t);
      set({ tools: updatedTools, isLoading: false, error: null });

      return formattedTool;
    } catch (error: any) {
      console.error('Error updating tool:', error);
      set({ error: error.message || 'Failed to update tool', isLoading: false });
      throw error;
    }
  },

  deleteTool: async (slug) => {
    set({ isLoading: true, error: null });
    try {
      const { error } = await supabase
        .from('tools')
        .delete()
        .eq('slug', slug);

      if (error) throw error;

      await get().fetchTools();
      set({ isLoading: false });
    } catch (error: any) {
      console.error('Error deleting tool:', error);
      set({ error: error.message || 'Failed to delete tool', isLoading: false });
      throw error;
    }
  },

  addCategory: (category) => {
    set((state) => ({
      categories: [...state.categories, category]
    }));
  },

  removeCategory: (category) => {
    set((state) => ({
      categories: state.categories.filter(c => c !== category)
    }));
  },

  migrateToolSlugs: async () => {
    try {
      const { data: tools, error } = await supabase
        .from('tools')
        .select('*')
        .is('slug', null);

      if (error) throw error;

      if (tools && tools.length > 0) {
        for (const tool of tools) {
          const baseSlug = generateSlug(tool.name);
          
          const { data: existingSlugs } = await supabase
            .from('tools')
            .select('slug')
            .like('slug', `${baseSlug}%`);

          let slug = baseSlug;
          let counter = 1;
          while (existingSlugs?.some(t => t.slug === slug)) {
            slug = `${baseSlug}-${counter}`;
            counter++;
          }

          const { error: updateError } = await supabase
            .from('tools')
            .update({ slug })
            .eq('id', tool.id);

          if (updateError) throw updateError;
        }
      }
    } catch (error: any) {
      console.error('Error migrating tool slugs:', error);
      throw error;
    }
  },
}));

export default useToolStore;
