import React, { useEffect, Suspense, memo } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';
import useToolStore from './store/toolStore';
import Header from './components/Header';
import Footer from './components/Footer';
import ErrorBoundary from './components/ErrorBoundary';
import LoadingSpinner from './components/LoadingSpinner';
import AuthGuard from './components/AuthGuard';
import { injectCriticalCSS, loadNonCriticalCSS } from './utils/cssOptimizer';

// Lazy load all pages
const HomePage = React.lazy(() => import('./pages/HomePage'));
const CategoriesPage = React.lazy(() => import('./pages/CategoriesPage'));
const AdminPage = React.lazy(() => import('./pages/AdminPage'));
const AdminPagesPage = React.lazy(() => import('./pages/AdminPagesPage'));
const AdminEditPagePage = React.lazy(() => import('./pages/AdminEditPagePage'));
const AddToolPage = React.lazy(() => import('./pages/AddToolPage'));
const ToolDetailPage = React.lazy(() => import('./pages/ToolDetailPage'));
const ToolsPage = React.lazy(() => import('./pages/ToolsPage'));
const EditToolPage = React.lazy(() => import('./pages/EditToolPage'));
const LoginPage = React.lazy(() => import('./pages/LoginPage'));
const RegisterPage = React.lazy(() => import('./pages/RegisterPage'));
const AboutPage = React.lazy(() => import('./pages/AboutPage'));
const ContactPage = React.lazy(() => import('./pages/ContactPage'));
const PrivacyPolicyPage = React.lazy(() => import('./pages/PrivacyPolicyPage'));
const TermsPage = React.lazy(() => import('./pages/TermsPage'));
const CookiePolicyPage = React.lazy(() => import('./pages/CookiePolicyPage'));
const AffiliateDisclosurePage = React.lazy(() => import('./pages/AffiliateDisclosurePage'));
const BlogPage = React.lazy(() => import('./pages/BlogPage'));
const BlogPostPage = React.lazy(() => import('./pages/BlogPostPage'));
const BlogCategoryPage = React.lazy(() => import('./pages/BlogCategoryPage'));
const AdminBlogPage = React.lazy(() => import('./pages/AdminBlogPage'));
const AdminNewBlogPost = React.lazy(() => import('./pages/AdminNewBlogPost'));
const AdminEditBlogPost = React.lazy(() => import('./pages/AdminEditBlogPost'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 1
    },
  },
});

// Wrap Header and Footer in memo to prevent unnecessary re-renders
const MemoizedHeader = memo(Header);
const MemoizedFooter = memo(Footer);

// Create a loading component for better suspense fallback
const PageLoader = memo(() => (
  <div className="min-h-screen flex items-center justify-center">
    <LoadingSpinner />
  </div>
));

function App() {
  const { fetchTools, migrateToolSlugs } = useToolStore();

  useEffect(() => {
    // Inject critical CSS immediately
    injectCriticalCSS();

    // Load non-critical CSS after the page has loaded
    if (document.readyState === 'complete') {
      loadNonCriticalCSS();
    } else {
      window.addEventListener('load', loadNonCriticalCSS);
    }

    return () => {
      window.removeEventListener('load', loadNonCriticalCSS);
    };
  }, []);

  useEffect(() => {
    const initializeApp = async () => {
      try {
        await migrateToolSlugs();
        await fetchTools();
      } catch (error) {
        console.error('Error initializing app:', error);
      }
    };

    initializeApp();
  }, [fetchTools, migrateToolSlugs]);

  useEffect(() => {
    // Load fonts
    import('./utils/fontLoader').then(({ loadFonts, preloadCriticalFonts }) => {
      // Preload critical fonts immediately
      preloadCriticalFonts();
      
      // Load remaining fonts after initial render
      requestIdleCallback(() => {
        loadFonts();
      });
    });
  }, []);

  useEffect(() => {
    // Clean up any existing preload links that aren't being used
    const preloadLinks = document.querySelectorAll('link[rel="preload"]');
    preloadLinks.forEach(link => {
      const href = (link as HTMLLinkElement).href;
      if (href.startsWith('data:')) {
        link.remove();
      }
    });
  }, []);

  return (
    <ErrorBoundary>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <Router>
            <div className="flex flex-col min-h-screen">
              <MemoizedHeader />
              <main className="flex-grow">
                <Suspense fallback={<PageLoader />}>
                  <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/categories" element={<CategoriesPage />} />
                    <Route path="/tools" element={<ToolsPage />} />
                    <Route path="/tool/:slug" element={<ToolDetailPage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/privacy" element={<PrivacyPolicyPage />} />
                    <Route path="/terms" element={<TermsPage />} />
                    <Route path="/cookies" element={<CookiePolicyPage />} />
                    <Route path="/affiliate-disclosure" element={<AffiliateDisclosurePage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/register" element={<RegisterPage />} />
                    <Route path="/admin" element={<AuthGuard requireAdmin={true}><AdminPage /></AuthGuard>} />
                    <Route path="/admin/pages" element={<AuthGuard requireAdmin={true}><AdminPagesPage /></AuthGuard>} />
                    <Route path="/admin/pages/:slug" element={<AuthGuard requireAdmin={true}><AdminEditPagePage /></AuthGuard>} />
                    <Route path="/admin/add" element={<AuthGuard requireAdmin={true}><AddToolPage /></AuthGuard>} />
                    <Route path="/admin/tools/edit/:slug" element={<AuthGuard requireAdmin={true}><EditToolPage /></AuthGuard>} />
                    <Route path="/blog" element={<BlogPage />} />
                    <Route path="/blog/:slug" element={<BlogPostPage />} />
                    <Route path="/blog/category/:slug" element={<BlogCategoryPage />} />
                    <Route path="/admin/blog" element={<AuthGuard requireAdmin={true}><AdminBlogPage /></AuthGuard>} />
                    <Route path="/admin/blog/new" element={<AuthGuard requireAdmin={true}><AdminNewBlogPost /></AuthGuard>} />
                    <Route path="/admin/blog/edit/:slug" element={<AuthGuard requireAdmin={true}><AdminEditBlogPost /></AuthGuard>} />
                  </Routes>
                </Suspense>
              </main>
              <MemoizedFooter />
            </div>
          </Router>
        </QueryClientProvider>
      </HelmetProvider>
    </ErrorBoundary>
  );
}

export default App;